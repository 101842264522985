.resume-address {
  margin-bottom: 21px;

  @media (max-width: $screen-medium) {
    margin-bottom: 0;
  }

  p {
    font-size: 18px;

    @media (max-width: $screen-medium) {
      font-size: 17px;
    }
  }

  p + p {
    margin-top: 4px;
  }

  .resume-address-fullname {
    text-shadow: 0 0 0.05rem $black;
  }

  .resume-address-fulladdress,
  .resume-address-phonenumber {
    color: $grey;
  }
}

.page-boutique {
  margin-bottom: 40px;

  .title {
    @include primaryFont;

    font-size: 40px;
    font-weight: 100;
    margin-bottom: 20px;

    @media (max-width: $screen-medium) {
      font-size: 28px;
    }
  }

  .store-information-page-container {
    display: flex;

    @media (max-width: $screen-medium) {
      flex-direction: column-reverse;
    }
  }

  .container {
    margin-top: 47px;
  }

  .left {
    margin-right: 20px;
    width: 36%;
    background-color: $light-grey-100;

    @media (max-width: $screen-medium) {
      background-color: $transparent;
      margin: 0;
      width: 100%;
    }

    .store-informations-services-accordion {
      padding: 16px 16px 0;

      @media (max-width: $screen-medium) {
        p {
          font-weight: 100;
        }

        .store-description {
          font-weight: 100;
          margin-top: 20px;
        }
      }

      .accordion .accordion-item-button {
        justify-content: space-between;
        padding: 0 0 12px;

        .accrodion-title {
          text-transform: none;
          font-weight: 400;
        }
      }
    }
  }

  .infos {
    padding: 16px;

    .map-list-sellers-bytype-item-title {
      font-size: 25px;
    }

    .map-list-sellers-bytype-item-title a {
      text-decoration: none;
      color: $black;
      font-weight: 500;
    }

    .address {
      margin-top: 20px;
      margin-bottom: 20px;
      line-height: 23px;
      font-size: 16px;
      font-weight: 300;
    }

    .number {
      display: flex;
      align-items: center;

      svg {
        width: 20px;
        height: 20px;
        margin-right: 13px;
      }

      h5 {
        margin: 0;
      }

      span {
        text-transform: lowercase;
        font-size: 18px;
        font-weight: 100;
      }
    }

    .see-itinerary-button {
      margin-top: 10px;
    }
  }

  .itinerary {
    display: flex;
    flex-flow: column;
    margin-bottom: 20px;

    .content-icons {
      width: 100%;
      justify-content: center;

      button.mobility {
        border-radius: 50%;
        background-color: $transparent-grey-10;
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 5px;

        svg {
          --color-fill: white;

          width: 20px;
          height: 20px;
        }

        &.active,
        &:hover {
          background-color: $white;

          svg {
            stroke: black;
            fill: black;
          }
        }
      }
    }

    .itinerary-form {
      background-color: $black;
      color: $white;
      padding: 20px 16px;

      @media (max-width: $screen-medium) {
        padding: 16px 8px;
      }

      .itinerary-form-transportationmodes {
        display: flex;
        gap: 8px;
        justify-content: center;
        margin-bottom: 12px;

        @media (max-width: $screen-medium) {
          gap: 12px;
        }

        .itinerary-form-transportationmodes-item {
          list-style: none;

          input[type='radio'] {
            display: none;

            &:checked + label {
              background-color: $light-grey-300;

              svg {
                --color-fill: white;
              }
            }
          }

          label {
            cursor: pointer;
            display: inline-block;
            border-radius: 50%;
            padding: 6px 6px 4px;
          }

          svg {
            --color-fill: #838383;

            width: 24px;
            height: 24px;
          }
        }
      }

      .itinerary-form-cities {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 6px;

        .itinerary-form-cities-pins {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 4px;

          .itinerary-form-cities-pins-bulletstart {
            width: 8px;
            height: 8px;
            background: $black;
            border: 2px solid $white;
            border-radius: 50%;
          }

          .itinerary-form-cities-pins-bullet {
            width: 4px;
            height: 4px;
            background: $light-grey-300;
            border-radius: 50%;
          }

          .itinerary-form-cities-pins-icon {
            width: 24px;
            height: 24px;
            fill: $white;
            stroke: $white;
          }
        }

        svg {
          --color-fill: white;

          width: 20px;
          height: 20px;
        }

        .itinerary-form-cities-inputs {
          padding: 0 24px;
          gap: 16px;
          flex: 1;
          display: flex;
          flex-direction: column;

          @media (max-width: $screen-medium) {
            padding: 0 12px;
          }

          input {
            display: block;
            padding: 6px 10px;
            font-size: 16px;
            width: calc(100% - 24px);
          }
        }

        .sens {
          border-radius: 50%;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            width: 32px;
            height: 32px;
            stroke: $white;
          }

          &:hover {
            background-color: $transparent-grey-10;
          }
        }
      }
    }

    .itinerary-results {
      padding: 16px;

      @media (max-width: $screen-medium) {
        display: block;
        padding-bottom: 0;
      }

      .accordion .accordion-item-button {
        justify-content: space-between;
        padding: 0 0 12px;

        .accrodion-title {
          text-transform: none;
          font-weight: 400;
        }
      }

      .itinerary-results-content {
        display: flex;
        flex-direction: column;
        gap: 12px;
        max-height: 500px;
        overflow: auto;

        .button {
          width: 100%;
          font-weight: 600;
          padding: 20px;
          text-align: center;
        }
      }
    }

    &.reverse-sens {
      .itinerary-results .itinerary-results-content {
        flex-direction: column-reverse !important;
      }
    }

    .itinerary-instructions {
      .itinerary-instructions-maininformation {
        padding: 8px 0;
        border-bottom: 1px solid $light-grey-200;
      }

      .itinerary-instructions-list {
        padding-right: 16px;

        .itinerary-instructions-list-item {
          padding: 8px 0;
          border-bottom: 1px solid $light-grey-200;
          display: grid;
          grid-template-columns: minmax(24px, auto) minmax(28px, auto) 1fr auto;
          grid-gap: 4px;
          cursor: pointer;

          .itinerary-instructions-list-item-content {
            b {
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  .right {
    width: 68%;
    margin-left: auto;

    @media (max-width: $screen-medium) {
      margin: 0;
      width: 100%;
    }

    .store-informations-services {
      padding: 16px;
      background-color: $light-grey-100;

      .store-description {
        margin-top: 30px;
        font-size: 14px;
        display: block;
      }
    }
  }

  .googleMap {
    width: 100%;
    height: 600px;
    margin-bottom: 24px;

    @media (max-width: $screen-medium) {
      height: 170px;
    }

    .store-information-map {
      width: 100%;
      height: 100%;
    }
  }

  .dist {
    border: 1px solid $white;
    margin: 5px;
    padding: 5px;
    display: block;
    cursor: pointer;
    width: auto;
    color: $white;
  }

  .little-info {
    text-decoration: underline;
    margin: 5px;
    padding: 5px;
  }

  .seller-item-openinghours {
    padding: 16px 0;

    @media (max-width: $screen-medium) {
      padding: 16px;
    }
  }
}

.store-informations-services {
  .store-informations-services-list {
    display: flex;
    gap: 16px;

    @media (max-width: $screen-medium) {
      flex-direction: column;
    }

    .store-informations-services-item {
      width: calc(50% - 8px);
      gap: 12px;
      align-items: center;
      flex-wrap: wrap;

      @media (max-width: $screen-medium) {
        width: 100%;
      }

      .store-informations-services-item-values {
        display: inline-flex;
        gap: 12px;
        flex-wrap: wrap;
        padding-top: 12px;

        span {
          padding: 12px;
          background-color: $white;
          box-shadow: 0 2px 4px rgb(45 147 226 / 10%);

          @media (max-width: $screen-medium) {
            font-weight: 100;
          }
        }
      }
    }
  }
}

// https://stackoverflow.com/questions/17941812/google-directions-api
.adp-substep {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;

  .adp-stepicon {
    display: inline-block;
    width: 100%;
    height: 100%;

    .adp-maneuver {
      background-image: url('http://maps.gstatic.com/mapfiles/api-3/images/maneuvers.png');
      background-size: 19px 630px;
      display: inline-block;
      width: 100%;
      height: 100%;

      &.adp-ferry {
        background-position: 0 -614px;
      }

      &.adp-ferry-train {
        background-position: 0 -566px;
      }

      &.adp-merge {
        background-position: 0 -143px;
      }

      &.adp-straight {
        background-position: 0 -534px;
      }

      &.adp-fork-left {
        background-position: 0 -550px;
      }

      &.adp-ramp-left {
        background-position: 0 -598px;
      }

      &.adp-roundabout-left {
        background-position: 0 -197px;
      }

      &.adp-turn-left,
      &.adp-keep-left {
        background-position: 0 -413px;
      }

      &.adp-turn-sharp-left {
        background-position: 0 0;
      }

      &.adp-turn-slight-left {
        background-position: 0 -378px;
      }

      &.adp-uturn-left {
        background-position: 0 -305px;
      }

      &.adp-fork-right {
        background-position: 0 -499px;
      }

      &.adp-ramp-right {
        background-position: 0 -429px;
      }

      &.adp-roundabout-right {
        background-position: 0 -232px;
      }

      &.adp-turn-right,
      &.adp-keep-right {
        background-position: 0 -483px;
      }

      &.adp-turn-sharp-right {
        background-position: 0 -582px;
      }

      &.adp-turn-slight-right {
        background-position: 0 -51px;
      }

      &.adp-uturn-right {
        background-position: 0 -35px;
      }
    }
  }
}

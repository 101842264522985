html[lang^='ar'] {
  direction: rtl;

  .header {
    .header-tools {
      direction: ltr;

      ul {
        margin-left: 0;
        margin-right: auto;
        flex-flow: row-reverse nowrap;
      }
    }
  }

  .title-h1 {
    line-height: 138px;

    @media (max-width: $screen-medium) {
      line-height: 177px;
    }
  }

  .page-collection .header-media-category .header-media-content .title-h1 {
    line-height: 110px;

    @media (max-width: $screen-medium) {
      line-height: 60px;
    }
  }

  .gift {
    article {
      direction: rtl;
    }
  }

  .bloc-navigation-right {
    transform: scaleX(-1);
    margin-left: 20px;
  }

  .navigation-container .bloc-navigation.bloc-navigation-left {
    transform: none;
    margin-left: 10px;
  }

  .footer {
    .footer-selects .footer-select {
      select {
        background-position: left;
        padding-right: 0;
        padding-left: 16px;
      }

      label {
        margin-left: 11px;
        margin-right: 0;
      }
    }
  }

  .filter-product-form {
    .filter-product-form-desktop {
      .filter-product-form-menu-filterby {
        .filter-product-form-menu-filterby-title {
          padding-right: 0;
          padding-left: 16px;
        }

        .filter-product-form-menu-filterby-item {
          span {
            margin-left: 12px;
            margin-right: 0;
          }
        }
      }

      .filter-product-form-menu {
        .filter-product-form-menu-right {
          .filter-product-form-nbarticle {
            border-right: none;
            padding-right: 0;
            margin-right: 0;

            &:not(:last-child) {
              padding-left: 24px;
              margin-left: 8px;
              border-left: 1px solid #ccc;
            }
          }
        }
      }
    }
  }

  .product-template {
    .pictures {
      margin-left: 0;
      margin-right: -80px;

      @media (max-width: $screen-medium) {
        margin: 0;
      }
    }

    .product-informations {
      .product_configuration {
        .title-h3 {
          margin-bottom: 22px;
          line-height: 48px;
        }

        .block-select {
          .select-container .right {
            margin-right: auto;
            margin-left: 0;
          }
        }

        #alma-widget {
          display: none;
        }
      }

      .carving-recap .carving-edit button {
        margin: -3px auto 0 0;
      }

      .accordion article .content .rdv {
        text-align: right;
      }
    }
  }

  .block-select {
    .select-container {
      label,
      .placeholder {
        padding-right: 0;
        padding-left: 8px;
      }

      .right {
        .size-guide {
          margin-left: 33px;
          margin-right: 0;
        }
      }
    }

    ul {
      text-align-last: right;
    }
  }

  .modal {
    .modal-carving {
      section {
        padding-right: 0;
        padding-left: 40px;
        width: 44%;
        text-align: right;

        @media (max-width: $screen-small) {
          width: 100%;
          padding: 0;
        }
      }
    }
  }

  .modal-overlay {
    .modal .close {
      right: auto;
      left: 16px;
    }

    &.modal-right .modal .close {
      right: 16px;
      left: auto;
    }
  }

  .header-search-input-mobile {
    input[type='text'] {
      padding: 7px 42px 8px 0;
    }
  }

  .header-search-results-title {
    margin-left: 0;
    margin-right: 20px;
  }

  .header-navigation-mobile {
    .header-navigation-mobile-contact a svg {
      margin-right: 0;
      margin-left: 16px;
    }
  }

  .header-navigation-mobile-link {
    .header-navigation-mobile-link-arrow.previous {
      margin-left: 8px;
      margin-right: 0;
      transform: rotate(0);
    }

    &.bold {
      span {
        font-weight: 700;
        font-size: 18px;
      }
    }
  }

  .header-navigation-mobile-seemore a,
  .header-navigation-mobile-link {
    svg {
      transform: rotate(180deg);
    }
  }

  .header-navigation-mobile-seemore a span {
    margin-right: 0;
    margin-left: 8px;
  }

  .header-navigation-mobile
    .header-navigation-mobile-menu
    .header-navigation-mobile-menu-items
    li
    .header-navigation-mobile-level-submenu
    ul:not(.header-navigation-mobile-categories-withimages)
    > li:not(.see-all) {
    padding-left: 20px;
    padding-right: 42px;
  }

  .footer .footer-nav ul .nav-title {
    @media (max-width: $screen-medium) {
      text-align: center;
    }
  }

  .header-navigation-desktop {
    .header-navigation-desktop-submenu {
      &.header-navigation-desktop-submenu--push {
        .header-navigation-desktop-blockcategory {
          .header-navigation-desktop-blockcategory-title {
            font-weight: 700;
            font-size: 20px;
          }
        }
      }
    }
  }

  .modal.dark {
    .header-modal {
      text-align: left;
    }
  }

  .text-wrapper {
    .text-wrapper-label {
      left: auto;
      right: 0;
      margin: 0;
      text-align: right;
    }
  }

  .address-form {
    .address-form-fields {
      &.autocomplete-fields button {
        text-align: right;
      }

      .inputs-phone-code-number {
        & > .inputs-phone-code-number-desc {
          text-align: right;
        }

        .block-select {
          input {
            &::placeholder {
              text-align: right;
            }
          }
        }

        .PhoneInputInput {
          text-align: end;
        }
      }
    }

    .address-form-buttons {
      margin-left: 0;
      margin-right: auto;
    }
  }

  .PhoneInputCountryIcon {
    margin-left: 5px;
  }

  .cart-item {
    &.item-small .content {
      margin-right: 19px;
    }

    &.item-small .push-tag {
      right: 0;
    }

    .content {
      margin-right: 24px;

      .quantity {
        direction: ltr;
        flex-direction: row-reverse;
      }
    }

    .cart-item-engraving {
      .form-input {
        .form-input-carving {
          button {
            left: 0;
            right: auto;
          }
        }

        label {
          margin-left: 8px;
          margin-right: 0;
          height: 30px;
          display: flex;
          flex-direction: column-reverse;

          &.typo-english {
            margin-top: 9px;
          }
        }
      }
    }

    &.cart-item-mobile {
      .head {
        button {
          right: unset;
          left: 0;
        }
      }
    }
  }

  .checkout-page {
    .checkoutworkflow-login {
      .checkoutworkflow-login-form.checkoutworkflow-login-form-guest {
        padding-right: 0;
        padding-left: 10%;

        @media (max-width: $screen-medium) {
          padding-bottom: 36px;
          padding-left: 0;
        }
      }

      .checkoutworkflow-login-form.checkoutworkflow-login-form-account {
        padding-left: 0;
        padding-right: 10%;

        @media (max-width: $screen-medium) {
          padding-right: 0;
        }

        .container .forgoten-password {
          margin-right: auto;
          margin-left: 0;
        }
      }

      .checkoutworkflow-login-form.checkoutworkflow-login-form-guest::after {
        left: 0;
        right: inherit;
      }
    }

    .checkoutworkflow-gift {
      .checkoutworkflow-gift-form {
        .checkoutworkflow-gift-form-message {
          .checkoutworkflow-gift-form-message-left {
            text-align: left;
            direction: ltr;
          }
        }
      }
    }
  }

  .cart-page {
    .cart-resume {
      .cart-resume-advantage-code {
        .advantage-form .advantage-form-button {
          right: inherit;
          left: 0;
        }

        .cart-resume-informations-item-advantage-code {
          span {
            margin-right: 0;
            margin-left: 8px;
          }
        }
      }

      .cart-resume-subtotal-old {
        margin-left: 12px;
      }
    }

    .link-with-icon {
      .icon {
        transform: rotate(0);
      }
    }

    .grids {
      .grid:first-child {
        margin: 0 0 0 30px;
      }
    }

    .information-cart {
      .information-frame {
        font-size: 14px;
      }

      .information-frame-help {
        font-size: 14px;

        a[aria-label='phone'] {
          direction: ltr;
        }

        .email-modal-button {
          font-size: 13px;
        }
      }

      p,
      a {
        font-size: 14px;
      }

      .return-modal-button {
        padding-right: 2px;
      }
    }
  }

  .account-page {
    .empty {
      p {
        margin-left: auto;
        margin-right: 15px;
      }
    }

    .navigation {
      border-right: none;
      border-left: 1px solid rgba($black, 0.1);
    }

    .account-nav {
      ul {
        li {
          .container {
            padding-left: 0;
            padding-right: 30px;

            &.link :is(button, span) {
              text-align: right;
            }
          }

          &.active {
            border-left: 2px solid $black;
            border-right: none;

            &::before {
              right: -30%;
              left: 0;
            }

            @media (max-width: $screen-medium) {
              &::after {
                right: auto;
                left: 10px;
              }
            }
          }
        }
      }
    }
  }

  .sellers-page {
    .sellers-page-blocs {
      padding-left: 0;
      padding-right: 24px;

      @media (max-width: $screen-medium) {
        padding: 0;
      }

      .sellers-filters-map {
        .sellers-page-filter-search .sellers-page-filter-search-cities {
          .map-input-cities {
            padding-left: 40px;
            padding-right: 10px;
          }

          .sellers-page-filter-search-cities-icon {
            left: 8px;
            right: auto;
          }
        }

        .sellers-page-filter-radius {
          .sellers-page-filter-radius-unity {
            .block-select ul {
              padding: 8px 55px 8px 15px;
            }
          }
        }
      }
    }

    .sellers-page-structure {
      .sellers-page-structure-title {
        padding-left: 0;
        padding-right: 24px;
      }
    }

    // close button of google maps on info window
    .gm-ui-hover-effect {
      right: auto !important;
      left: 0 !important;
    }
  }

  .map-list-sellers {
    .map-list-sellers-bytype {
      .map-list-sellers-bytype-item {
        .map-list-sellers-bytype-item-address {
          .map-list-sellers-bytype-item-address-phone
            .map-list-sellers-bytype-item-address-phone-icon {
            margin-left: 12px;
            margin-right: 0;
          }
        }
      }
    }
  }

  .page-boutique {
    .left {
      margin-left: 20px;
      margin-right: 0;
    }

    .infos {
      .number svg {
        margin-left: 13px;
        margin-right: 0;
      }
    }
  }

  .login-page section.login .flex .container-switch label {
    margin-left: 16px;
    margin-right: auto;
  }

  // FORM
  form {
    .date {
      input {
        text-align: right;
        flex-direction: row-reverse;
        display: block;
        line-height: 30px;
      }
    }
  }

  .register-page form .date {
    input {
      line-height: 20px;
      margin-top: 5px;

      @media (max-width: $screen-small) {
        line-height: 15px;
      }
    }

    &::after {
      right: auto;
      bottom: 7px;
      left: 0;

      @media (max-width: $screen-small) {
        bottom: 9px;
      }
    }
  }

  .page-newsletter .modal-contact .date::after {
    right: auto;
    left: 4px;
    bottom: 7px;

    @media (max-width: $screen-small) {
      bottom: 9px;
      left: 6px;
    }
  }

  .go-back-to-cart svg {
    margin-left: 4px;
    transform: rotate(0);
  }

  label.switch {
    span.decorator::after {
      transform: translate3d(26.5px, 2.5px, 0);
    }

    input:checked + span.decorator::after {
      transform: translate3d(2.5px, 2.5px, 0) !important;
    }
  }

  .register-page {
    input[type='date']::-webkit-calendar-picker-indicator {
      position: absolute;
      left: 6px;
      top: 0;
      opacity: 0;
    }

    section.register .container-switch label.switch span.decorator::after {
      transform: translate3d(18.5px, 2.5px, 0);
    }
  }

  .header label.switch span.decorator::after {
    transform: translate3d(18.5px, 2.5px, 0);
  }

  .summary {
    .header-order .price {
      padding-left: 0;
      padding-right: 28px;
    }

    .summary-list .summary-item .container {
      margin-left: 0;
      margin-right: auto;
    }
  }

  .cms-block-noheadless {
    .hero-infos .page-title-wrapper .page-title span {
      @media (max-width: $screen-medium) {
        font-weight: 600;
      }
    }

    .hero-container {
      .hero-flex-container {
        text-align: right;
      }

      .hero-content {
        .cover-titles div {
          line-height: 1.1;
        }

        h2 span {
          font-weight: 600;
        }

        p {
          font-weight: 600;
        }
      }
    }

    .Block .fat-title {
      right: 0;
      left: inherit;

      span {
        transform: translate3d(54%, 50%, 0) rotate(-90deg);
      }
    }

    .Block-08 {
      .Item-05 {
        padding: 0 91px 0 0;

        @media (max-width: $legacy-screen-l) {
          padding: 0;

          .Item-title,
          .Item-desc {
            text-align: right;
          }
        }

        .Item-title-01 {
          left: inherit;
          right: -290px;
          z-index: 3;
          transform: translate3d(0%, 50%, 0) rotate(-90deg);
          width: auto;
          bottom: 50%;

          @media (max-width: $legacy-screen-l) {
            right: -170px;
          }

          span {
            transform: none;
          }
        }
      }

      .Item-06 {
        padding: 0 0 0 63px;
      }

      &.Block-08--alt .Item-05 .Item-title-01 {
        right: -200px;

        @media (max-width: $legacy-screen-l) {
          right: -115px;
        }
      }
    }

    .Block-09 {
      .Grid-02 {
        .fat-title {
          right: 5px;

          @media (max-width: $legacy-screen-l) {
            left: inherit;
            right: inherit;
          }
        }
      }

      .fat-title {
        right: 28px;
        left: inherit;

        @media (max-width: $legacy-screen-l) {
          left: inherit;
          right: inherit;
        }
      }
    }

    .Block-10 {
      .Cols-2 {
        .Item-08 {
          padding: 0 40px 0 203px;

          &:first-child {
            padding: 0 203px 0 40px;
          }
        }

        .fat-title {
          transform: translate3d(60%, 50%, 0) rotate(-90deg);
        }
      }
    }

    .Item-07 {
      align-items: center;

      .Item-infos {
        align-self: auto;
      }

      .Item-title {
        text-align: right;

        @media (max-width: $screen-medium) {
          text-align: center;
        }
      }
    }

    .Block-02 {
      .Item {
        right: 11%;
        left: inherit;

        @media (max-width: $legacy-screen-l) {
          right: auto;
        }
      }

      .Item-title:lang(ar),
      p:lang(ar) {
        text-align: right;
      }
    }

    .Item-06 .Item-title,
    .Item-05 .Item-title {
      text-align: right;
    }

    &.cms-a-diamond-heritage {
      .Block-02 .img-container .title {
        @media (max-width: $legacy-screen-l) {
          text-align: right;
        }
      }

      .Block-08 .Item-05 {
        .Item-title-01 {
          right: -310px;

          @media (max-width: $legacy-screen-l) {
            right: -179px;
          }
        }

        @media (max-width: $legacy-screen-l) {
          .Item-desc {
            text-align: center;
          }
        }
      }

      .Block-08.Block-08--alt .Item-05 .Item-title-01 {
        right: -130px;

        @media (max-width: $legacy-screen-l) {
          right: -70px;
        }
      }
    }

    &.cms-an-exceptional-know-how {
      .Block-08 .Item-05 .Item-title-01 {
        right: -235px;

        @media (max-width: $legacy-screen-l) {
          right: -136px;
        }
      }

      @media (max-width: $legacy-screen-l) {
        .Block-02 .img-container .title .align-r {
          margin: 0 90px 0 0;
          display: inline-block;
          text-align: right;
        }
      }
    }

    &.cms-a-tribute-to-feminity {
      .Block-08 .Item-05 .Item-title-01 {
        right: -124px;

        @media (max-width: $legacy-screen-l) {
          right: -70px;
        }
      }

      .Block-08.Block-08--alt .Item-05 .Item-title-01 {
        right: -154px;

        @media (max-width: $legacy-screen-l) {
          right: -88px;
        }
      }
    }

    &.cms-the-messika-vision {
      .Block-08 .Item-05 .Item-title-01 {
        right: -150px;

        @media (max-width: $legacy-screen-l) {
          right: -80px;
        }
      }

      .Block-08.Block-08--alt .Item-05 .Item-title-01 {
        right: -120px;

        @media (max-width: $legacy-screen-l) {
          right: -68px;
        }
      }
    }
  }

  .modal-contact form .form-control select {
    background: $transparent url('/_next/static/media/icon-nav-arrow-down.78bc8c80.svg');
    background-position: center left 8px;
    background-repeat: no-repeat;
    background-size: 16px;
  }

  .faq-page-container
    .mageprince-faq-collection
    .faq-content
    .faq-accordion
    .faq-accordion-subGroup {
    .faq-accordion-subGroup-title {
      padding-right: 40px;
      padding-left: 72px;

      &::before,
      &::after {
        left: 50px;
        right: inherit;
      }
    }
  }

  .page-error {
    text-align: right;
  }

  .to-top {
    left: 0;
    right: inherit;
    margin-right: auto;
    margin-left: 160px;

    @media (max-width: $screen-medium) {
      margin-left: 20px;
    }
  }

  .to-top.alone:not(.onleft) {
    left: 0;
    right: inherit;
    margin-right: auto;
    margin-left: 30px;
  }

  #launcher {
    right: inherit !important;
    left: 0 !important;
  }

  .celebrities-page .celebrities-item:not(:nth-child(4n)) {
    margin-left: 20px;
    margin-right: 0;
  }

  // STICKY YEAR

  .event-page-container .Blog-container.thisism-container .Sticky-year,
  .event-page-container .Blog-container.messikatv-container .Sticky-year,
  .event-page .Sticky-year {
    left: inherit;
    right: -27px;
    transform: translate3d(-34%, -50%, 0) rotate(-90deg);
    font-size: 136px;

    @media (max-width: $screen-medium) {
      right: -30px;
      font-size: 68px;
    }

    &.isSticky {
      right: 50%;
      transform: translate3d(-22%, -50%, 0) rotate(-90deg);

      @media (max-width: $screen-medium) {
        transform: translate3d(-8%, -50%, 0) rotate(-90deg);
      }
    }
  }

  @media (max-width: $screen-medium) {
    .celebrities-page .celebrities-item-date {
      right: inherit;
      left: 0;
    }

    .celebrities-page .celebrities-item-name {
      text-align: right;
    }
  }

  button.gm-control-active.gm-fullscreen-control {
    right: auto !important;
    left: 0 !important;
  }

  .gmnoprint.gm-style-mtc-bbw {
    left: auto !important;
    right: 0 !important;
  }

  .faq-page-container
    .mageprince-faq-collection
    .faq-content
    .faq-accordion
    .faq-accordion-subGroup
    .faq-accordion-subGroup-content
    .subFaqAccordion
    .ui-accordion-header
    .fa-icon-cross::before,
  .faq-page-container
    .mageprince-faq-collection
    .faq-content
    .faq-accordion
    .faq-accordion-subGroup
    .faq-accordion-subGroup-content
    .subFaqAccordion
    .ui-accordion-header
    .fa-icon-cross::after {
    left: 50px;
    right: inherit;

    @media (max-width: $screen-medium) {
      left: 30px;
    }
  }

  .faq-page-container
    .mageprince-faq-collection
    .faq-content
    .faq-accordion
    .faq-accordion-subGroup
    .faq-accordion-subGroup-content
    .subFaqAccordion
    .ui-accordion-header {
    @media (max-width: $screen-medium) {
      padding: 30px 0 30px 60px;
    }
  }

  .wishlist .wish .wish-actions .button-remove-to-wishlist {
    padding: 0;

    &::before {
      margin-right: 0;
      margin-left: 16px;
    }
  }

  .block-select span.error,
  .checkbox-wrapper span.error,
  .container-gender span.error,
  .terms-of-use .error,
  .text-wrapper span.error {
    text-align: right;
    left: inherit;
    right: 0;
  }

  .DropinComponent-module_adyen-checkout__payment-method__image__wrapper__6NWzA {
    margin-right: 0;
    margin-left: 8px;
  }

  .adyen-checkout__payment-method__header__title {
    margin: 0;
  }

  .select-wrapper.default .select-wrapper-label {
    text-align: right;
    right: 0;
    left: inherit;
    margin: 0;
  }

  .accordion article .title-h5 {
    text-align: right;
  }

  .filter-product-form .filter-product-form-fields-item span {
    direction: ltr;
  }

  .modal-contact form .form-control label {
    margin: 0;
  }

  .modal-contact form {
    padding-right: 0;
    padding-left: 40px;

    @media (max-width: $screen-medium) {
      padding: 0;
    }
  }

  .my-orders table tbody tr td:first-child {
    border-right: 1px solid $black-transparent-10;
    border-left: 0;
  }

  .my-orders table tbody tr td:last-child {
    border-left: 1px solid $black-transparent-10;
    border-right: 0;
  }

  .page-newsletter {
    .left-side {
      margin-right: 0;
      margin-left: 30px;
    }

    .left-side .form-control.checkbox label {
      margin-left: 0;
      margin-right: 10px;
    }

    input[type='date' i]::-webkit-calendar-picker-indicator {
      position: absolute;
      left: 10px;
      top: 10px;
      opacity: 0;
    }
  }

  .message-push a {
    line-height: 22px;
  }

  .breadcrumbs ul li::after {
    margin-left: 0;
    margin-right: 5px;
  }

  form label {
    margin-right: 0;
    margin-left: 16px;
  }

  @media (max-width: $legacy-screen-l) {
    .Block-08 .Item-07 {
      margin: 0 -40px 0 0;
    }
  }

  .page-homepage .list-news .news-item div {
    text-align: right !important;
  }

  .resume-address {
    .resume-address-phonenumber {
      direction: ltr;
      display: flex;
      flex-direction: row-reverse;
    }
  }

  .error-message {
    text-align-last: right;
    white-space: nowrap;
  }

  .page-newsletter .modal-contact .date:has(.error-message) {
    &::after {
      bottom: 34px;
    }
  }

  .breadcrumbs ul li a,
  .breadcrumbs ul li span {
    font-weight: 300;
  }

  .cms-page-view {
    .page-main .column.main .Block-08 .Item-05,
    .page-main .column.main .Block-08 .Item-06 {
      @media (max-width: $screen-medium) {
        padding: 0;
      }
    }

    .hero-container .hero-content .cover-titles div:last-child,
    .hero-container .hero-content .cover-titles div > span:last-child {
      padding-left: 0;
      padding-right: 32px;
    }
  }

  .header .header-helpers {
    .helpers-border {
      padding-left: 24px;
      padding-right: 0;

      &:not(:last-child)::after {
        left: 5px;
        right: inherit;
        top: 6px;
      }
    }

    label.switch span.decorator {
      margin-top: 4px;
    }

    .numbers a {
      direction: ltr;
      display: block;
    }
  }

  .header-navigation-desktop
    .header-navigation-desktop-submenu.header-navigation-desktop-submenu--push
    .header-navigation-desktop-blockcategory
    .header-navigation-desktop-category
    a {
    background-position: 0 85%;
  }

  .header .header-helpers li.contrast-icon {
    @media (max-width: $screen-medium) {
      margin-right: -13px;
      margin-left: 0;
    }
  }

  .faq-page-group {
    .container-slider-nav-faq:has(.arrow-navigation .faq-groups-arrow-right:not(.disabled))
      .swiper {
      padding-right: 0;
      padding-left: 30px;
    }

    .arrow-navigation .faq-groups-arrow {
      transform: translate3d(0, 10%, 0) rotate(180deg);

      @media (max-width: $screen-medium) {
        transform: translate3d(0, -85%, 0) rotate(180deg);
      }

      &.faq-groups-arrow-left {
        right: -6px;
        left: inherit;
      }

      &.faq-groups-arrow-right {
        left: -6px;
        right: inherit;
      }
    }
  }

  .header-navigation-mobile .see-all a svg {
    margin-left: 10px;
    transform: rotate(180deg);
    margin-right: 10px;
  }

  .terms-of-use {
    input {
      right: 0;
      left: unset;
    }

    button {
      text-align: right;
    }
  }

  .modal-return {
    p {
      text-align: right;
    }
  }

  .modal-geoip-others .footer-selects .footer-select::after {
    right: inherit;
    left: 10px;
  }

  .modal-notify-in-stock .select-wrapper {
    &.default select {
      padding: 7px 0 7px 16px;

      @media (max-width: $screen-medium) {
        padding: 9px 0 9px 19px;
      }
    }

    .select-wrapper-icon {
      left: 0;
      right: inherit;
    }
  }

  .login-page {
    section.login .flex a.forgoten-password {
      margin: 0;
      display: block;
    }

    label.switch span.decorator::after {
      transform: translate3d(18.5px, 2.5px, 0);
    }
  }

  .register-page section.register {
    .container-switch .switch,
    label.switch span.label {
      margin: 0;
    }
  }

  .map-list-sellers
    .map-list-sellers-bytype
    .map-list-sellers-bytype-item
    .map-list-sellers-bytype-item-address
    .map-list-sellers-bytype-item-address-phone
    span,
  .info-window-store-content .info-window-store-content-phone span:lang(ar),
  .page-boutique .infos .number span {
    direction: ltr;
    display: inline-block;
  }

  .info-window-store-content .info-window-store-content-phone svg {
    margin-right: 0;
    margin-left: 4px;
  }

  .header-search-results .header-search-results-left {
    border-left: 1px solid #f0f0f0;
    border-right: 0;
  }

  .search-page
    .search-products-page
    .search-products-page-blocs
    .search-products-page-bloc-results
    .search-products-page-bloc-results-resume
    .search-products-page-bloc-results-resume-nbhits {
    margin-top: 0;
  }

  .filter-product-form-menu-orderby {
    margin-top: -7px;
  }

  .filter-product-form
    .resume-selected-filters
    .resume-selected-filters-list
    .resume-selected-filters-item
    .resume-selected-filters-item-title {
    margin-right: 0;
    margin-left: 10px;
  }

  section.alerts table tbody td .alert-label {
    text-align: right;
  }

  .cms-block-noheadless .Block-09 .Video-wrapper,
  .cms-block-noheadless .Block-09 .Image-wrapper {
    @media (max-width: $screen-medium) {
      margin-left: 0;
      width: 100%;
    }
  }

  .cms-page-view.page-layout-1column-fullwidth-content .widget.block .Block-09 {
    @media (max-width: $screen-medium) {
      padding: 0;
    }
  }

  .cms-page-view.page-layout-1column-fullwidth-content .Block-09.Block--full .Video-wrapper,
  .cms-page-view.page-layout-1column-fullwidth-content .Block-09.Block--full .Image-wrapper {
    @media (max-width: $screen-medium) {
      margin-right: 10px;
      margin-left: 0;
      width: 100%;
    }
  }

  .header-navigation-mobile .see-all a {
    padding-left: 0;
    padding-right: 23px;
  }

  .product-template .product-informations .accordion article .content .phone a {
    direction: ltr;
    display: inline-block;
    margin-right: 6px;
    margin-left: 0;
  }
}

html[lang^='ar'] {
  .text-wrapper {
    .toggle-password-visibility {
      left: 0;
      right: auto;
      top: 8px;
    }
  }
}

.text-wrapper {
  position: relative;

  .text-wrapper-label {
    pointer-events: none;
    position: absolute;
    bottom: 7px;
    left: 0;
    transition: all 0.1s ease;
    font-size: 16px;
  }

  .toggle-password-visibility {
    margin-top: 0;
    position: absolute;
    right: 0;
    top: 2px;
    cursor: pointer;
    font-size: 16px;
    color: $black;

    svg {
      width: 26px;
    }

    @media (max-width: $screen-small) {
      margin-top: -10px;
    }
  }

  input {
    @include primaryFont;

    border: none;
    border-bottom: 1px solid $black;
    padding: 8px 0;
    color: $black;
    font-size: 16px;

    &:focus + label,
    &:not(:placeholder-shown) + label {
      bottom: 100%;
      font-size: 12px;
      pointer-events: auto;
    }

    &::placeholder {
      opacity: 0;
    }
  }

  @media (max-width: $screen-small) {
    .text-wrapper-label {
      font-size: 14px;
      bottom: 4px;
    }

    input {
      font-size: 14px;
      padding: 2px 0;
    }
  }
}

.container-gender,
.block-select,
.text-wrapper,
.checkbox-wrapper {
  position: relative;

  span.error {
    color: $red;
    position: absolute;
    left: 0;
    bottom: -15px;
    font-size: 12px;
    height: 10px;
  }
}

.container-gender {
  span.error {
    position: static;
  }
}

.address-form {
  .address-help-text-wrapper {
    text-align: left;
    color: $grey;
    font-size: 12px;
    margin-bottom: 24px;
  }

  .address-field-title {
    display: flex;
    gap: 16px * 2;
    align-items: center;
    margin-bottom: 12px;

    button {
      font-size: 12px;
      text-decoration: underline;
    }
  }

  .block-select ul.open {
    width: 270px;
    height: 350px;
    position: absolute;
    border: 1px solid $light-grey-200;
    overflow-y: scroll;
    margin-top: 9px;
    padding: 10px;
    z-index: 9;
  }

  .container-gender {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px 60px;
    margin: 42px 0 33px;

    @media (max-width: $screen-medium) {
      gap: 10px 30px;
      justify-content: space-between;
    }
  }

  .address-form-defaultinputs {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .input-checkbox.disabled {
      .checkbox-text {
        color: $grey;
      }
    }
  }

  .address-form-buttons {
    margin-left: auto;
    width: fit-content;
    margin-top: 20px;
  }

  .address-form-fields {
    display: flex;
    flex-wrap: wrap;
    gap: 33px 24px;
    margin-bottom: 32px;

    &.hide {
      display: none !important;
    }

    & > * {
      width: calc(50% - 12px);

      @media (max-width: $screen-medium) {
        width: 100%;
      }

      &.address-form-gender-radios {
        width: 100%;
      }
    }

    .address-form-gender-radios {
      display: flex;
      flex-direction: row;
      gap: 24px;
    }

    input[type='text'],
    select {
      width: 100%;

      &:focus {
        outline: none;
      }
    }

    .inputs-city-zip-code {
      display: flex;
      gap: 24px;

      > .input-city {
        width: calc(60% - 12px);
      }

      > .input-zip-code {
        width: calc(40% - 12px);
      }
    }

    .inputs-phone-code-number {
      display: flex;
      flex-wrap: wrap;
      gap: 24px 24px;
      width: 100%;

      .PhoneInputCountrySelectArrow {
        margin-left: 12px;
        opacity: 1;
      }

      .block-select {
        margin-top: 0;
        padding-top: 0;
        padding-bottom: 6px;
        width: auto;
        border-top: none;
        border-bottom-color: $black;

        .PhoneInput {
          gap: 15px;

          .PhoneInputCountry {
            margin-right: 0;
            margin-bottom: 4px;
          }
        }

        input {
          padding: 0;
          border: none;
          font-size: 16px;
          line-height: 1;

          @media (max-width: $screen-medium) {
            font-size: 14px;
          }

          &:focus {
            outline: none;
          }
        }
      }

      > .input-phone-number {
        flex: 1;
        max-width: calc(60% - 12px);
        text-align: left;

        @media (max-width: $screen-medium) {
          max-width: calc(100% - 24px - 135px);
        }

        @media (max-width: $screen-small) {
          input {
            width: 100%;
            height: 100%;
            padding: 0;
          }
        }
      }

      > .input-phone-code {
        width: calc(40% - 12px);
      }

      > .inputs-phone-code-number-desc {
        width: 100%;
        font-size: 12px;
        font-weight: 400;
        color: $grey;
        text-align: left;
      }

      > .inputs-phone-code-number-desc-top {
        width: 100%;
        font-size: 12px;
        font-weight: 400;
        color: $grey;
        text-align: left;
        padding-top: 25px;
      }
    }

    &.autocomplete-fields {
      display: block;

      .autocomplete {
        width: 100%;
      }

      .places-autocomplete input {
        padding-bottom: 4px;
        padding-left: 0;
        padding-right: 0;
        border: none;
        border-bottom: 1px solid $black;
        border-radius: 0;
        font-size: 16px;
        min-height: 1px;

        @media (max-width: $screen-medium) {
          padding-bottom: 2px;
        }

        &--is-focused,
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }

      .autocomplete-select {
        &__control {
          padding-bottom: 4px;
          border: none;
          border-bottom: 1px solid $black;
          border-radius: 0;
          min-height: 1px;

          @media (max-width: $screen-medium) {
            padding-bottom: 2px;
          }

          &--is-focused,
          &:focus {
            outline: none;
            box-shadow: none;
          }
        }

        &__value-container {
          padding: 0;
        }

        &__placeholder {
          margin: 0;
          color: $grey;
          font-size: 16px;

          @media (max-width: $screen-medium) {
            font-size: 14px;
          }
        }

        &__input-container {
          margin: 0;
          padding: 0;
        }

        &__indicator {
          padding: 0;

          &-separator {
            display: none;
          }
        }

        &__dropdown-indicator {
          width: 16px;
          height: 16px;
          background: url('../../icons/icon-nav-arrow-down.svg') right no-repeat;
          background-size: 16px;

          svg {
            display: none;
          }
        }

        &__menu {
          margin: -1px 0 0;
          border-radius: 0;
          box-shadow: none;
          border-bottom: 1px solid $light-grey-200;
        }

        &__option {
          &--is-focused,
          &:hover {
            background-color: $light-grey-200;
          }
        }
      }

      .error {
        color: $red;
        font-size: 12px;
      }

      button {
        letter-spacing: inherit;
        text-decoration: underline;
        font-weight: 400;
        border: none;
        font-size: 16px;
        text-align: left;
        padding: 0;
        text-transform: unset;
        margin-top: 10px;

        &::before {
          display: none;
        }

        &:hover {
          color: $black;
        }
      }

      .places-autocomplete-list button {
        margin-top: 0;
        text-decoration: none;
        padding: 6px 12px;
      }
    }
  }

  &.address-validation {
    text-align: left;

    .validation-items {
      margin-top: 24px;

      .validation-item {
        display: flex;
        gap: 10px;
        justify-content: space-between;

        .validation-content {
          .validation-content-title {
            font-size: 14px;
            margin-bottom: 15px;
          }
        }

        button {
          text-decoration: underline;
          font-size: 16px;
        }
      }
    }
  }
}

.deliverytype-form {
  .deliverytype-form-fields {
    margin-bottom: 32px;
  }

  .deliverytype-form-radios {
    & > .input-radio {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 32px;

      @media (max-width: $screen-medium) {
        margin-bottom: 16px;
      }

      & > * {
        &:nth-child(1) {
          flex: initial;
        }

        &:nth-child(3) {
          flex: 1;
        }
      }

      .deliverytype-radio-text {
        span {
          font-size: 16px;

          @media (max-width: $screen-medium) {
            font-size: 14px;
          }
        }

        .deliverytype-radio-text-title {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        }

        .deliverytype-radio-text-title,
        .deliverytype-radio-text-content {
          font-size: 16px;
        }

        .deliverytype-radio-text-content {
          color: $grey;
        }

        @media (max-width: $screen-medium) {
          .deliverytype-radio-text-title,
          .deliverytype-radio-text-content {
            font-size: 14px;
          }
        }

        .deliverytype-radio-text-subtitle {
          color: $grey;
        }
      }
    }
  }

  .selectslot-wrapper {
    &.hide {
      display: none;
    }

    .modal-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .modal-content-submit {
        text-align: right;
      }
    }

    .selectslot-wrapper-actions {
      margin-top: 16px;

      .selectslot-wrapper-actions-desc {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        align-items: center;

        .selectslot-wrapper-actions-modify {
          text-decoration: underline;
        }
      }

      .button {
        width: 100%;
      }
    }
  }

  .mobile-button-open-modal {
    width: 100%;
  }

  .deliverytype-form-submit {
    margin-left: auto;
    width: fit-content;
  }
}
